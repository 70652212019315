import { useLocation, useNavigate } from "react-router-dom";
import styles from "./OpenWorkOrder.module.css";
import { CustomButton, RoundButton } from "../../../ui-kit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { workOrderMockData } from "../../../utils/09.2_workOrderMockData"; // возвращает один объект по нашему айдишинку
import { positionsMockData } from "../../../utils/05_positionsMockData";
import { ACTIONS_LIST_PATH, ADD_INSTUCTIONS_PATH } from "../../../utils/constants";

function OpenWorkOrder(): React.JSX.Element {

  // запрос с полученным айди
  const location = useLocation();
  const { appointmentId } = location.state || {};
  console.log(appointmentId);
  //

  const getPositionNameById = (id: number): string | undefined => {
    const position = positionsMockData.find(position => position.id === id);
    return position ? position.name : undefined;
  };

  const formatDueDate = (dueDate: number[]): string => {
    const [year, month, day] = dueDate;

    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const getDaySuffix = (day: number): string => {
      if (day >= 11 && day <= 13) return `${day}th`;
      switch (day % 10) {
        case 1: return `${day}st`;
        case 2: return `${day}nd`;
        case 3: return `${day}rd`;
        default: return `${day}th`;
      }
    };

    const monthName = monthNames[month - 1];
    const dayWithSuffix = getDaySuffix(day);

    return `${monthName} ${dayWithSuffix}, ${year}`;
  };


  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleOnActionsListClick = () => {
    navigate(ACTIONS_LIST_PATH);
  };
  const handleOnAddInstructionsClick = () => {
    navigate(ADD_INSTUCTIONS_PATH);
  };

  return (
    <div className={`${styles.main} container`}>
      <h1>Work Order</h1>

      <div className={styles.data_container}>
        <h3 className={styles.text_right}>#{appointmentId}</h3>
        <p className={`p2 ${styles.text_left}`}>
          {workOrderMockData.workOrderShortDto.taskShortDto.name}
        </p>
        <h3 className={styles.text_right}>Goal:</h3>
        <p className={`p2 ${styles.text_left}`}>
          {workOrderMockData.workOrderShortDto.taskShortDto.goal}
        </p>

        <h3 className={styles.text_right}>Due Date:</h3>
        <p className={`p2 ${styles.text_left}`}>{formatDueDate(workOrderMockData.workOrderShortDto.dueDate)}</p>

        <h3 className={styles.text_right}>Position:</h3>
        <p className={`p2 ${styles.text_left}`}>{getPositionNameById(workOrderMockData.workOrderShortDto.taskShortDto.founderPositionId)}</p>

        <h3 className={styles.text_right}>Grade:</h3>
        <p className={`p2 ${styles.text_left}`}>{workOrderMockData.workOrderShortDto.taskShortDto.grade}</p>
      </div>

      <CustomButton text="Actions List" onClick={handleOnActionsListClick} />
      <CustomButton text="Add Instructions" onClick={handleOnAddInstructionsClick} />

      <div className={styles.avatar}>
        <img src="./avatar.png" alt="img" width='100px' height='100px' />
        <h2>Bid: {workOrderMockData.workOrderShortDto.taskShortDto.defaultBid}</h2>
      </div>

      <div className={`${styles.bonus_container}`}>
        <RoundButton
          icon={<RemoveIcon />}
          altText="Remove icon"
          disabled={false}
        />
        <h2>Bonus: 100</h2>
        <RoundButton
          icon={<AddIcon />}
          altText="Add icon"
          disabled={false}
        />
      </div>

      <p className="p4">
        By clicking "Accept" I confirm that I have read and agree to the privacy policy, cookie processing policy, accept the user agreement and consent to the processing of my personal data in accordance with the consent to the processing of personal data
      </p>

      <div className="narrow_buttons_container">

        <CustomButton
          text="Back"
          onClick={handleBackClick}
          color={"var(--violet-7)"}
          textColor={"dark"}
          size="narrow"
        />
        <CustomButton
          text="Accept"
          color={"var(--green)"}
          textColor={"dark"}
          size="narrow"
        />
      </div>


    </div>
  );
}

export default OpenWorkOrder;
