import { Route, Routes, useNavigate } from "react-router-dom";

import styles from "./app.module.css";

import {
  MAIN,
  FOUNDER_MAIN_PATH,
  MY_SPRINTS_PATH,
  NEW_SPRINT_PATH,
  SPRINT_RETRO_PATH,
  STARTUP_TRACK_PATH,
  UI_KIT_PATH,
  URGENT_TASKS_PATH,
  REGISTER,
  MYASSIGNMENTS,
  NUMBER,
  WALLET,
  WALLETTICKET,
  NEWJOB,
  ACTIONLIST,
  FILTERS,
  UNASSIGNED_WORK_ORDER_PATH,
  REGISTER_USER,
  REGISTER_SKILLS,
  NEW_SPRINT_INFO_PATH,
  ADD_INSTUCTIONS_PATH,
  ACTIONS_LIST_PATH,
  REVISION_REQUEST_PATH,
  OPEN_WORK_ORDER_PATH,
  IN_REVIEW_WORK_ORDER_PATH,
  STARTUP_ACCOUNT,
} from "../../utils/constants";

import UiKit from "../../pages/UiKit/UiKit";
import FounderMain from "../../pages/founder/FounderMain/FounderMain";
import MainTemp from "../../pages/main/main-temp";
import Register from "../../pages/register/register";
import MyAssignments from "../../pages/cobuilder/myAssignments/myAssignments";
import WorkOrder from "../../pages/work0order/workOrder";
import Wallet from "../../pages/cobuilder/wallet/wallet";
import WalletTicket from "../../pages/cobuilder/walletTicket/walletTicket";
import NewJob from "../../pages/cobuilder/newJob/newJob";
import MySprints from "../../pages/founder/MySprints/MySprints";
import ActionList from "../../pages/actionList/actionList";
import Filters from "../../pages/filters/filters";
import StartupTrack from "../../pages/founder/StartupTrack/StartupTrack";
import NewSprint from "../../pages/founder/NewSprint/NewSprint";
import NewSprintInfo from "../../pages/founder/NewSprintInfo/NewSprintInfo";
import UnassignedWorkOrder from "../../pages/founder/UnassignedWorkOrder/UnassignedWorkOrder";
import UrgentTasks from "../../pages/founder/UrgentTasks/UrgentTasks";
import SprintRetro from "../../pages/founder/SprintRetro/SprintRetro";
import RegisterUser from "../../pages/registerUser/registerUser";
import RegisterSkills from "../../pages/registerSkills/registerSkills";
import AddInstructions from "../../pages/founder/AddInstructions/AddInstructions";
import ActionsList from "../../pages/founder/ActionsList/ActionsList";
import RevisionRequest from "../../pages/founder/RevisionRequest/RevisionRequest";
import OpenWorkOrder from "../../pages/founder/OpenWorkOrder/OpenWorkOrder";
import InReviewWorkOrder from "../../pages/founder/InReviewWorkOrder/InReviewWorkOrder";
import { useEffect, useState } from "react";
import { getUser } from "../../utils/api";
import Loader from "../loader/loader";
import StartupAccount from "../../pages/founder/StartupAccount/StartupAccount";

type TelegramUser = {
  id: number;
  username: string;
  first_name?: string;
  last_name?: string;
  photo_url?: string;
};

function App(): React.JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userTelegramData, setUserTelegramData] = useState<TelegramUser | null>(
    null
  );

  useEffect(() => {
    setLoading(true);

    // @ts-ignore
    const tg = window.Telegram.WebApp;
    tg.ready(); // Сигнализирует Telegram, что Web App готов
    setUserTelegramData(tg.initDataUnsafe.user || null);
    localStorage.setItem(
      "telegramId",
      JSON.stringify(tg.initDataUnsafe.user?.id)
    );
    localStorage.setItem(
      "telegramUserName",
      JSON.stringify(tg.initDataUnsafe.user?.username)
    );
    localStorage.setItem(
      "telegramUserAvatar",
      JSON.stringify(tg.initDataUnsafe.user?.photo_url)
    );
    localStorage.setItem("telegramUserPlatform", JSON.stringify(tg.platform));

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    //@ts-ignore
    getUser(userTelegramData?.id).then((res) => {
      if (res.statusCode !== null && res.coBuilderShortDto === null) {
        navigate(FOUNDER_MAIN_PATH);
        localStorage.setItem(
          "founderData",
          JSON.stringify(res.founderShortDto)
        );
        setLoading(false);
      } else if (
        res.founderShortDto === null &&
        res.coBuilderShortDto !== null
      ) {
        navigate(MYASSIGNMENTS);
        localStorage.setItem(
          "cobuilderData",
          JSON.stringify(res.coBuilderShortDto)
        );
        setLoading(false);
      } else if (res.errors) {
        navigate(REGISTER_USER);
        setLoading(false);
      }
    });
  }, [userTelegramData]);

  return (
    <div className={styles.app}>
      {loading && (
        <div className={styles.loader}>
          <Loader />
          <p>Loading ...</p>
        </div>
      )}

      {!loading && (
        <Routes>
          {/* <Route
            path={MAIN}
            element={<MainTemp userTelegramData={userTelegramData} />}
          /> */}
          <Route path={FILTERS} element={<Filters />} />
          {/* FounderRoutes */}
          <Route path={FOUNDER_MAIN_PATH} element={<FounderMain />} />
          <Route path={MY_SPRINTS_PATH} element={<MySprints />} />
          <Route path={STARTUP_TRACK_PATH} element={<StartupTrack />} />
          <Route
            path={UNASSIGNED_WORK_ORDER_PATH}
            element={<UnassignedWorkOrder />}
          />
          <Route
            path={IN_REVIEW_WORK_ORDER_PATH}
            element={<InReviewWorkOrder />}
          />
          <Route path={OPEN_WORK_ORDER_PATH} element={<OpenWorkOrder />} />
          <Route path={SPRINT_RETRO_PATH} element={<SprintRetro />} />
          <Route path={URGENT_TASKS_PATH} element={<UrgentTasks />} />
          <Route path={NEW_SPRINT_PATH} element={<NewSprint />} />
          <Route path={NEW_SPRINT_INFO_PATH} element={<NewSprintInfo />} />
          <Route path={ADD_INSTUCTIONS_PATH} element={<AddInstructions />} />
          <Route path={ACTIONS_LIST_PATH} element={<ActionsList />} />
          <Route path={REVISION_REQUEST_PATH} element={<RevisionRequest />} />
          <Route path={STARTUP_ACCOUNT} element={<StartupAccount />} />

          {/* CoBuilderRoutes */}
          <Route path={MYASSIGNMENTS} element={<MyAssignments />} />
          <Route path={`${MYASSIGNMENTS}${NUMBER}`} element={<WorkOrder />} />
          <Route path={WALLET} element={<Wallet />} />
          <Route path={`${WALLETTICKET}${NUMBER}`} element={<WalletTicket />} />
          <Route path={NEWJOB} element={<NewJob />} />
          <Route
            path={`${MYASSIGNMENTS}${NUMBER}${ACTIONLIST}`}
            element={<ActionList />}
          />

          {/* NoRegisteredRoutes */}
          {/* <Route path={REGISTER} element={<Register />} /> */}
          <Route path={REGISTER_USER} element={<RegisterUser />} />
          <Route path={REGISTER_SKILLS} element={<RegisterSkills />} />

          {/* <Route path={UI_KIT_PATH} element={<UiKit />} /> */}
        </Routes>
      )}
    </div>
  );
}

export default App;
