import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./myAssignments.module.css";
import Assignment from "../../../components/assignment/assignment";
import TitleContainer from "../../../components/titleContainer/titleContainer";
import CustomSmallButton from "../../../ui-kit/CustomSmallButton/CustomSmallButton";
import { CustomButton } from "../../../ui-kit";
import { MAIN, MYASSIGNMENTS, NEWJOB, WALLET } from "../../../utils/constants";
import { getCoBuilder } from "../../../utils/api";

type AssignmentType = {
  id: string;
  title: string;
  status: string;
};

function MyAssignments(): React.JSX.Element {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const [assignments, setAssignments] = useState<AssignmentType[]>([]);

  useEffect(() => {
    const rawData = localStorage.getItem("cobuilderData");
    let userData;

    try {
      if (rawData) {
        userData = JSON.parse(rawData)?.id;
      }
    } catch (error) {
      console.error("Failed to parse cobuilderData:", error);
    }

    if (userData) {
      getCoBuilder(userData).then((res) => {
        //@ts-ignore
        setAssignments(res);
      });
    }
  }, []);

  const displayedAssignments = (assignments || []).slice(0, 3);

  const handleOnFullListClick = () => setFilter(true);
  const handleOnBackClick = () => setFilter(false);
  const handleOnNewJobClick = () => navigate(NEWJOB);
  const handleOnWalletClick = () => navigate(WALLET);

  // const handleOnClickToMain = () => {
  //   navigate(MYASSIGNMENTS);
  // };

  return (
    <div className={`${styles.main} container`}>
      <div className={styles.title_buttons_container}>
        <TitleContainer title="My Assignments" isLogo={true} isFilter={true} />
        <div className={styles.buttons_container}>
          <CustomSmallButton
            text="Assigned"
            color="var(--violet-7)"
            height="24px"
          />
          <CustomSmallButton
            text="In Review"
            color="var(--yellow)"
            height="24px"
          />
          <CustomSmallButton
            text="Revision"
            color="var(--coral)"
            height="24px"
          />
        </div>
      </div>
      <div className={styles.container}>
        {displayedAssignments.map((item) => (
          <Assignment item={item} key={item.id} />
        ))}
      </div>
      {!filter && displayedAssignments.length > 0 ? (
        <CustomButton
          text="Full List of Tasks"
          onClick={handleOnFullListClick}
          color={"var(--green)"}
          textColor={"dark"}
        />
      ) : (
        ""
      )}

      {!filter ? (
        <>
          <CustomButton
            text="Find new Job"
            onClick={handleOnNewJobClick}
            color={"var(--green)"}
            textColor={"dark"}
          />
          <CustomButton
            text="My Wallet"
            onClick={handleOnWalletClick}
            color={"var(--green)"}
            textColor={"dark"}
          />
        </>
      ) : (
        <CustomButton
          text="Back"
          onClick={handleOnBackClick}
          color={"var(--violet-7)"}
          textColor={"dark"}
        />
      )}
      {/* <CustomButton text="To MAIN Page" onClick={handleOnClickToMain} /> */}
    </div>
  );
}

export default MyAssignments;
