export const MAIN: string = "/";
export const FOUNDER_MAIN_PATH: string = "/founder-main";
export const COBUILDERMAIN: string = "/co-builder-main";
export const REGISTER: string = "/register";
export const FILTERS: string = "/filters";
export const NUMBER: string = "/:number";

export const UI_KIT_PATH: string = "/uikit";

// FounderRoutes
export const MY_SPRINTS_PATH: string = "/my-sprints";
export const PROFILE_PATH: string = "/profile";
export const STARTUP_TRACK_PATH: string = "/startup-track";
export const SPRINT_RETRO_PATH: string = "/sprint-retro";
export const URGENT_TASKS_PATH: string = "/urgent-tasks";
export const NEW_SPRINT_PATH: string = "/new-sprint";
export const NEW_SPRINT_INFO_PATH: string = "/new-sprint-info";
export const UNASSIGNED_WORK_ORDER_PATH: string = "/unassigned-work-order";
export const IN_REVIEW_WORK_ORDER_PATH: string = "/in-review-work-order";
export const OPEN_WORK_ORDER_PATH: string = "/open-work-order";
export const ADD_INSTUCTIONS_PATH: string = "/add-instruction";
export const ACTIONS_LIST_PATH: string = "/action-list";
export const REVISION_REQUEST_PATH: string = "/revision_request";
export const STARTUP_ACCOUNT: string = "/startup_account";

// CoBuilderRoutes
export const MYASSIGNMENTS: string = "/my-assignments";
export const WALLET: string = "/wallet";
export const WALLETTICKET: string = "/wallet-ticket";
export const NEWJOB: string = "/new-job";
export const ACTIONLIST: string = "/action-list";

export const REGISTER_USER: string = "/register-user";
export const REGISTER_SKILLS: string = "/register-skills";

//api
export const BASE_URL: string = "https://tracker.5dhub.tech/api/";
export const COBUILDERS: string = "cobuilders";
export const POSITIONS: string = "positions";
export const PRIMARY_LANGUAGES: string = "primarylanguages";
export const SKILLS: string = "skills";
export const USERS: string = "users";
export const TELEGRAM: string = "telegram";
export const WORKORDERS: string = "workorders";
export const LISTS: string = "lists";
