import { useNavigate } from "react-router-dom";
import styles from "./NewSprint.module.css";
import { CustomButton } from "../../../ui-kit";
import { NEW_SPRINT_INFO_PATH } from "../../../utils/constants";
import NewTask from "../../../components/pagesComponents/founder/NewSprint/NewTask";
//import { workOrdersListsStartups } from "../../../utils/14_workOrdersListsStartupsMockData";
import { useEffect, useState } from "react";
import { getStartups, getWorkOrdersByStartupId, WorkOrder } from "../../../utils/api";
import { FounderShortDto } from "../../../utils/types";
import FounderTitle from "../../../components/pagesComponents/founder/FounderTitle/FounderTitle";

function NewSprint(): React.JSX.Element {
  const [sortOrders, setSortOrders] = useState<WorkOrder[] | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(NEW_SPRINT_INFO_PATH);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("founderData");

    if (storedData) {
      const parsedData: FounderShortDto = JSON.parse(storedData);

      const fetchStartups = async () => {
        try {
          const startups = await getStartups(parsedData.id);

          if (startups) {
            const orders = await getWorkOrdersByStartupId(startups[0].id); //1

            const urgentTasks = orders.filter(
              (task) => task.status === "OPEN" && task.appointmentId === null
            );

            const resultTasks = urgentTasks.sort((a, b) => {
              const dateA = new Date(a.startDate[0], a.startDate[1] - 1, a.startDate[2]);
              const dateB = new Date(b.startDate[0], b.startDate[1] - 1, b.startDate[2]);
              return dateB.getTime() - dateA.getTime();
            });

            setSortOrders(resultTasks);
          }

        } catch (error) {
          console.error("Failed to fetch startups:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStartups();
    } else {
      console.log("Data not found in localStorage");
      setLoading(false);
    }
  }, [])

  // const openTasks = workOrdersListsStartups.filter(
  //   (task) => task.status === "OPEN" && task.appointmentId === null
  // );

  if (loading) {
    return <p>Loading founder data...</p>;
  }

  return (
    <div className={`${styles.main} container`}>
      <div className={styles.wrapper}><FounderTitle title="Plan a new sprint" isLogo={true} isFilter={true} /></div>
      <h2>Sunday fun day!</h2>
      <p className={`${styles.wrapper_text} p2`}>
        Every Sunday will bring you more open tasks that need to be approved.
      </p>

      <div className={styles.container}>
        {sortOrders && sortOrders?.length > 0 ? (
          sortOrders.map((data) => (
            <NewTask
              key={data.workOrderId}
              workOrderDto={data}
            />
          ))
        )
          : (
            <p>No Open orders found.</p>
          )}
      </div>

      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default NewSprint;
