import { useNavigate } from "react-router-dom";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import { FILTERS } from "../../../../utils/constants";
import { RoundButton } from "../../../../ui-kit";
import { useEffect, useState } from "react";
import { FounderShortDto } from "../../../../utils/types";

type TitleContainerProps = {
  title: string;
  isLogo: boolean;
  isFilter: boolean;
};

function FounderTitle({
  title,
  isLogo,
  isFilter,
}: TitleContainerProps): React.JSX.Element {
  const [founderData, setFounderData] = useState<FounderShortDto | undefined>();

  const navigate = useNavigate();

  const coBuilderInitials = founderData
    ? `${founderData.userDto.firstName.charAt(
        0
      )}${founderData.userDto.lastName.charAt(0)}`
    : "";

  const coBuilderAvatar = founderData?.userDto.avatarUrl;

  const handleFilterClick = (): void => {
    navigate(FILTERS);
  };

  //   const handleAvatarClick = (): void => {
  //     navigate(MYASSIGNMENTS);
  //   };

  useEffect(() => {
    const storedData = localStorage.getItem("founderData");

    if (storedData) {
      const parsedData: FounderShortDto = JSON.parse(storedData);
      setFounderData(parsedData);
    } else {
      console.log("Data not found in localStorage");
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "40px",
          width: "40px",
        }}
      >
        {isLogo && !coBuilderAvatar ? (
          <RoundButton
            disabled={false}
            // onClick={handleAvatarClick}
            text={coBuilderInitials}
          />
        ) : (
          <RoundButton
            disabled={false}
            // onClick={handleAvatarClick}
            img={coBuilderAvatar}
          />
        )}
      </div>
      <h1>{title}</h1>
      <div
        style={{
          height: "40px",
          width: "40px",
        }}
      >
        {isFilter && (
          <RoundButton
            icon={<TuneTwoToneIcon />}
            altText="TuneTwoToneIcon icon"
            disabled={false}
            variant="dark"
            onClick={handleFilterClick}
          />
        )}
      </div>
    </div>
  );
}

export default FounderTitle;
