import { useLocation, useNavigate } from "react-router-dom";
import styles from "./UnassignedWorkOrder.module.css";
import { CustomButton } from "../../../ui-kit";
import { workOrderMockData } from "../../../utils/09.1_workOrderMockData"; // возвращает один объект по нашему айдишинку
import { positionsMockData } from "../../../utils/05_positionsMockData";
import { ACTIONS_LIST_PATH } from "../../../utils/constants";

function UnassignedWorkOrder(): React.JSX.Element {
  const navigate = useNavigate();

 
  // запрос с полученным айди workOrderId
  const location = useLocation();
  const { workOrderId } = location.state || {};
  console.log(workOrderId);
  //

  const getPositionNameById = (id: number): string | undefined => {
    const position = positionsMockData.find(position => position.id === id);
    return position ? position.name : undefined;
  };

  const formatDueDate = (dueDate: number[]): string => {
    const [year, month, day] = dueDate;

    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const getDaySuffix = (day: number): string => {
      if (day >= 11 && day <= 13) return `${day}th`;
      switch (day % 10) {
        case 1: return `${day}st`;
        case 2: return `${day}nd`;
        case 3: return `${day}rd`;
        default: return `${day}th`;
      }
    };

    const monthName = monthNames[month - 1];
    const dayWithSuffix = getDaySuffix(day);

    return `${monthName} ${dayWithSuffix}, ${year}`;
  };


  const handleBackClick = () => {
    navigate(-1);
  };

  const handleOnActionsListClick = () => {
    navigate(ACTIONS_LIST_PATH);
  };

  return (
    <div className={`${styles.main} container`}>
      <h1>Work Order</h1>

      <div className={styles.data_container}>
        <h3 className={styles.text_right}>#{workOrderId}</h3>
        <p className={`p2 ${styles.text_left}`}>
        {workOrderMockData.workOrderShortDto.taskShortDto.name}
        </p>
        <h3 className={styles.text_right}>Goal:</h3>
        <p className={`p2 ${styles.text_left}`}>
        {workOrderMockData.workOrderShortDto.taskShortDto.goal}
        </p>

        <h3 className={styles.text_right}>Due Date:</h3>
        <p className={`p2 ${styles.text_left}`}>{formatDueDate(workOrderMockData.workOrderShortDto.dueDate)}</p>

        <h3 className={styles.text_right}>Position:</h3>
        <p className={`p2 ${styles.text_left}`}>{getPositionNameById(workOrderMockData.workOrderShortDto.taskShortDto.founderPositionId)}</p>

        <h3 className={styles.text_right}>Grade:</h3>
        <p className={`p2 ${styles.text_left}`}>{workOrderMockData.workOrderShortDto.taskShortDto.grade}</p>
      </div>

      <CustomButton
        text="Unassigned"
        color="var(--violet-7)" 
        size="narrow"
        textColor="dark"
      />

      <CustomButton text="Actions List" onClick={handleOnActionsListClick} />
      <CustomButton text="View instructions" onClick={handleOnActionsListClick} />

      <div className={styles.logo_texts_container}>
        <div className={styles.texts_container}>
          <div className={styles.text_container}>
            <h2>Bid:</h2>
            <p className="p1">100</p>
          </div>
          <div className={styles.text_container}>
            <h2>Bonus:</h2>
            <p className="p1">100</p>
          </div>
        </div>
      </div>


      <CustomButton text="Back" onClick={handleBackClick} />
    </div>
  );
}

export default UnassignedWorkOrder;
