import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./newJob.module.css";
import { CustomButton } from "../../../ui-kit";
import TitleContainer from "../../../components/titleContainer/titleContainer";
import Job from "../../../components/job/job";
import { MYASSIGNMENTS } from "../../../utils/constants";
import { TypeCoBuilderData } from "../../../utils/types";
import { getNewCoBuilderTasks } from "../../../utils/api";

function NewJob(): React.JSX.Element {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);

  const coBuilderData: TypeCoBuilderData | null = JSON.parse(
    localStorage.getItem("cobuilderData") || "null"
  );

  const coBuilderId = coBuilderData?.id || null;

  useEffect(() => {
    if (coBuilderId !== null) {
      getNewCoBuilderTasks(coBuilderId).then((res) => {
        //@ts-ignore
        setTasks(res);
      });
    }
  }, []);

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <TitleContainer title={"Find new Job"} isLogo={true} isFilter={false} />
      <div className={styles.container}>
        {tasks.map((item) => (
          <Link
            //@ts-ignore
            to={`${MYASSIGNMENTS}/${item.workOrderId}`}
            className={styles.link}
            key={uuidv4()}
          >
            <Job item={item} />
          </Link>
        ))}
      </div>
      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default NewJob;
