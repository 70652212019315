import { useNavigate } from "react-router-dom";
import styles from "./StartupTrack.module.css";
import { CustomButton } from "../../../ui-kit";
import CustomSmallButton from "../../../ui-kit/CustomSmallButton/CustomSmallButton";
import { useEffect, useState } from "react";
import { FounderShortDto } from "../../../utils/types";
import { getAllWorkOrdersByStartup, getStartups, StageForTrack } from "../../../utils/api";
import { formatDate } from "../../../utils/formatDate";
import FounderTitle from "../../../components/pagesComponents/founder/FounderTitle/FounderTitle";
//import { workOrderMockData } from "../../../utils/08_workOrderMockData";

function StartupTrack(): React.JSX.Element {
  const [sortedStages, setSortedStages] = useState<StageForTrack[]>();
  const [currentStageIndex, setCurrentStageIndex] = useState<number>(0);

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  type Status =
    | 'OPEN'
    | 'CREATED'
    | 'UNASSIGNED'
    | 'ASSIGNED'
    | 'DROPPED'
    | 'DONE'
    | 'IN_REVIEW'
    | 'REVISION'
    | 'ACCEPTED'
    | 'REJECTED';

  const getButtonConfig = (status: string) => {
    const config: Record<Status, { text: string; color: string }> = {
      OPEN: { text: 'Open', color: 'var(--white)' },
      CREATED: { text: '', color: 'var(--violet-1)' },
      UNASSIGNED: { text: 'Unassigned', color: 'var(--violet-7)' },
      ASSIGNED: { text: 'Assigned', color: 'var(--violet-7)' },
      DROPPED: { text: 'Dropped', color: 'var(--coral)' },
      DONE: { text: 'Done', color: 'var(--yellow)' },
      IN_REVIEW: { text: 'In Review', color: 'var(--orange-sm)' },
      REVISION: { text: 'Revision', color: 'var(--green-sm)' },
      ACCEPTED: { text: 'Accepted', color: 'var(--green)' },
      REJECTED: { text: 'Rejected', color: 'var(--coral)' },
    };

    if (status in config) {
      return config[status as Status];
    }

    return { text: 'Unknown', color: 'var(--gray)' };
  };

  // const getCurrentDate = (): [number, number, number] => {
  //   const now = new Date();
  //   return [now.getFullYear(), now.getMonth() + 1, now.getDate()];
  // };


  useEffect(() => {
    const storedData = localStorage.getItem("founderData");

    if (!storedData) {
      console.log("Data not found in localStorage");
      return;
    }

    const fetchStartups = async () => {
      try {
        const parsedData: FounderShortDto = JSON.parse(storedData);
        if (!parsedData.id) throw new Error("Founder ID is missing");

        const startups = await getStartups(parsedData.id);
        if (!startups || startups.length === 0) throw new Error("No startups found");

        const orderResponse = await getAllWorkOrdersByStartup(startups[0].id);

        const sortedStages = orderResponse.stageForTrackList
          .sort((a, b) => {
            const stageNumA = Number(a.fullName.split('.')[1].split(' ')[2]);
            const stageNumB = Number(b.fullName.split('.')[1].split(' ')[2]);
            return stageNumA - stageNumB;
          })
          .map(stage => ({
            ...stage,
            sprintForTrackList: stage.sprintForTrackList.sort((a, b) => {
              const numA = Number(a.fullName.split('.')[1].split(' ')[2]);
              const numB = Number(b.fullName.split('.')[1].split(' ')[2]);
              return numA - numB;
            }),
          }));


        // const formattedStages = sortedStages.map(stage => ({
        //   ...stage,
        //   shortName: stage.fullName.split('.')[1],
        //   formattedSprints: stage.sprintForTrackList.map(sprint => ({
        //     ...sprint,
        //     sprintNumber: sprint.fullName.split('.')[1].split(' ')[2],
        //   })),
        // }));

        setSortedStages(sortedStages);
      } catch (error) {
        console.error("Failed to fetch startups:", error);
      }
    };

    fetchStartups();
  }, []);




  // useEffect(() => {
  //   const today = getCurrentDate();

  //   const defaultStageIndex = stageForTrackList.findIndex((stage) =>
  //     stage.sprintForTrackList.some((sprint) => {
  //       const sprintDate = sprint.sprintStartDate;
  //       return (
  //         sprintDate[0] === today[0] &&
  //         sprintDate[1] === today[1] &&
  //         sprintDate[2] === today[2]
  //       );
  //     })
  //   );

  //   setCurrentStageIndex(defaultStageIndex === -1 ? 0 : defaultStageIndex);
  // }, [stageForTrackList]);


  return (
    <div className={`${styles.main} container`}>

      <div className={styles.title_buttons_container}>
        <FounderTitle title="Startup track" isLogo={true} isFilter={true} />
        {/* <div className={styles.buttons_container}>
          <CustomSmallButton
            text="Accepted"
            color="var(--green)"
            height="24px"
          />
          <CustomSmallButton
            text="Dropped"
            color="var(--coral)"
            height="24px"
          />
        </div> */}
      </div>

      <div className={styles.stages_container}>
        {sortedStages && sortedStages.map((stage, index) => (
          <CustomSmallButton
            key={index}
            text={stage.fullName.split('.')[1]}
            onClick={() => setCurrentStageIndex(index)}
            color={currentStageIndex === index ? "var(--violet-9)" : "var(--violet-7)"}
            width="96px"
            borderColor="var(--violet-gr-1)"
            borderWidth="1px"
          />
        ))}
      </div>

      <div className={styles.dates_container}>
        {sortedStages && sortedStages[currentStageIndex] && sortedStages[currentStageIndex]?.sprintForTrackList.map(
          (sprint, sprintIndex) => (
            <div key={sprintIndex}>
              <div className={styles.dates_container_button}>
                <div className={styles.dates_container_button_element}>
                  {formatDate(sprint.sprintStartDate)}
                </div>
                <div className={styles.dates_container_button_s}>
                  S{sprint.fullName.split('.')[1].split(' ')[2]}
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div className={styles.content_container}>
        <h2>PRODUCT MANAGEMENT TRACK</h2>

        <div className={styles.products_container}>
          {sortedStages && sortedStages[currentStageIndex] && sortedStages[currentStageIndex]?.sprintForTrackList.map((sprint, sprintIndex) => {
            if (sprintIndex === 0 || sprintIndex === 1 || sprintIndex === 2 || sprintIndex === 3) {
              return (
                <div key={sprintIndex}>
                  <div className={styles.flexContainer}>
                    <div className={styles.flexContainer_item}>
                      {sprint.workOrderForTrackList.map((item, index) => (
                        item.founderPositionShortDto.id === 3 && (
                          <div key={index}>
                            {(() => {
                              const { text, color } = getButtonConfig(item.status);
                              return (
                                <CustomSmallButton
                                  text={text}
                                  color={color}
                                  height="24px"
                                />
                              );
                            })()}
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                </div>
              );
            }
            return (<div className={styles.flexContainer}>
              <div className={styles.flexContainer_item}>
              </div>
            </div>);
          })}

        </div>
      </div>

      <div className={styles.content_container}>
        <h2>MARKETING & SALES TRACK</h2>

        <div className={styles.marketing_container}>

          {sortedStages && sortedStages[currentStageIndex] && sortedStages[currentStageIndex]?.sprintForTrackList.map((sprint, sprintIndex) => {
            if (sprintIndex === 0 || sprintIndex === 1 || sprintIndex === 2 || sprintIndex === 3) {
              return (
                <div key={sprintIndex}>
                  <div className={styles.flexContainer}>
                    <div className={styles.flexContainer_item}>
                      {sprint.workOrderForTrackList.map((item, index) => (
                        item.founderPositionShortDto.id === 2 && (
                          <div key={index}>
                            {(() => {
                              const { text, color } = getButtonConfig(item.status);
                              return (
                                <CustomSmallButton
                                  text={text}
                                  color={color}
                                  height="24px"
                                />
                              );
                            })()}
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                </div>
              );
            }
            return (<div className={styles.flexContainer}>
              <div className={styles.flexContainer_item}>
              </div>
            </div>);
          })}

        </div>
      </div>

      <div className={styles.content_container}>
        <h2>SOFTWARE DEVELOPMENT TRACK</h2>

        <div className={styles.software_container}>

          {sortedStages && sortedStages[currentStageIndex] && sortedStages[currentStageIndex]?.sprintForTrackList.map((sprint, sprintIndex) => {
            if (sprintIndex === 0 || sprintIndex === 1 || sprintIndex === 2 || sprintIndex === 3) {
              return (
                <div key={sprintIndex}>
                  <div className={styles.flexContainer}>
                    <div className={styles.flexContainer_item}>
                      {sprint.workOrderForTrackList.map((item, index) => (
                        item.founderPositionShortDto.id === 1 && (
                          <div key={index}>
                            {(() => {
                              const { text, color } = getButtonConfig(item.status);
                              return (
                                <CustomSmallButton
                                  text={text}
                                  color={color}
                                  height="24px"
                                />
                              );
                            })()}
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                </div>
              );
            }
            return (<div className={styles.flexContainer}>
              <div className={styles.flexContainer_item}>
              </div>
            </div>);
          })}

        </div>
      </div>

      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default StartupTrack;
