import { useNavigate } from "react-router-dom";
import styles from "./AddInstructions.module.css";
import { CustomButton, CustomTextField, RoundButton } from "../../../ui-kit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";

function AddInstructions(): React.JSX.Element {

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  const handleClick = () => {
    console.log("Button clicked!");
  };

  const [inputInstructionsValue, setInputInstructionsValue] = useState('');
  const [inputReferencesValue, setInputReferencesValue] = useState('');

  const handleInputInstructionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputInstructionsValue(value);
  };

  const handleInputReferencesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputReferencesValue(value);
  };

  return (
    <div className={`${styles.main} container`}>
      <h1>Add Instructions</h1>

      <h2>Your Instructions:</h2>

      <CustomTextField value={inputInstructionsValue} onChange={handleInputInstructionsChange} />

      <h2>References:</h2>

      <CustomTextField value={inputReferencesValue} onChange={handleInputReferencesChange} />

      <div className={`${styles.bonus_container}`}>
        <RoundButton
          icon={<RemoveIcon />}
          altText="Remove icon"
          disabled={false}
          onClick={handleClick}
        />
        <h2>Bonus: 100</h2>
        <RoundButton
          icon={<AddIcon />}
          altText="Add icon"
          disabled={false}
          onClick={handleClick}
        />

      </div>

      <CustomButton text="Add" onClick={handleOnClick} />
    </div>
  );
}

export default AddInstructions;
