import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import styles from "./registerSkills.module.css";
import { CustomButton } from "../../ui-kit";
import { MYASSIGNMENTS } from "../../utils/constants";
import {
  cobuilderRegister,
  getLanguages,
  getPositions,
  getSkills,
  Position,
  Language,
} from "../../utils/api";
import CustomSelect from "../../ui-kit/CustomSelect/CustomSelect";

interface Skill {
  id: number;
  name: string;
}

interface SkillCategory {
  skillCategoryShortDto: {
    id: number;
    name: string;
    skillType: string;
  };
  skillShortDtoList: Skill[];
}

function RegisterSkills(): React.JSX.Element {
  const navigate = useNavigate();

  const [positions, setPositions] = useState<Position[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [skills, setSkills] = useState<SkillCategory[]>([]);
  const [chosenPositionId, setChosenPositionId] = useState<number | null>(null);
  //@ts-ignore
  const platform = JSON.parse(localStorage.getItem("telegramUserPlatform"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [positionsData, languagesData] = await Promise.all([
          getPositions(),
          getLanguages(),
        ]);
        //@ts-ignore
        setPositions(positionsData);
        //@ts-ignore
        setLanguages(languagesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const groupedSkills = skills.map((category) => ({
    name: category.skillCategoryShortDto.name
      .toLowerCase()
      .replace(/\s+/g, "_"),
    label: category.skillCategoryShortDto.name,
    options: [
      { id: 0, name: "-" }, // Добавляем эту опцию
      ...category.skillShortDtoList.map((skill) => ({
        id: skill.id,
        name: skill.name,
      })),
    ],
  }));

  const onPositionChange = async (value: string) => {
    const chosenPosition = positions.find(
      (position) => position.name === value
    );
    if (chosenPosition) {
      setChosenPositionId(chosenPosition.id);
      try {
        const skillsData = await getSkills(chosenPosition.id);
        //@ts-ignore
        setSkills(skillsData);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    }
  };

  const [submittable, setSubmittable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const handleFormSubmit = async () => {
    const selectedSkillIds: number[] = [];
    const selectedLanguageIds: number[] = [];

    Object.entries(values).forEach(([category, selectedSkills]) => {
      if (Array.isArray(selectedSkills)) {
        const categoryData = skills.find(
          (item) =>
            item.skillCategoryShortDto.name
              .toLowerCase()
              .replace(/\s+/g, "_") === category
        );

        if (categoryData) {
          selectedSkills.forEach((skillName: string) => {
            const skill = categoryData.skillShortDtoList.find(
              (item) => item.name === skillName
            );
            if (skill && skill.id !== 0) {
              // Проверка на id === 0
              selectedSkillIds.push(skill.id);
            }
          });
        }
      }
    });

    if (values.language) {
      (values.language as string[]).forEach((languageName: string) => {
        const language = languages.find((lang) => lang.name === languageName);
        if (language) {
          selectedLanguageIds.push(language.id);
        }
      });
    }

    const payload = {
      positionId: chosenPositionId,
      skillIds: selectedSkillIds,
      languageIds: selectedLanguageIds,
    };

    try {
      await cobuilderRegister(payload);
      navigate(MYASSIGNMENTS);
    } catch (error) {
      console.error("Error registering:", error);
    }
  };

  return (
    <div
      className={`${
        platform === "ios" ? styles.main_ios : styles.main
      } container`}
    >
      <Form
        form={form}
        name="skills"
        layout="vertical"
        autoComplete="off"
        className={styles.form}
      >
        <CustomSelect
          options={languages.map((lang) => ({
            id: lang.id,
            name: lang.name,
          }))}
          name={"language"}
          label={"Primary Language"}
          mode="multiple"
        />
        <CustomSelect
          options={positions.map((position) => ({
            id: position.id,
            name: position.name,
          }))}
          name={"position"}
          label={"Cobuilder's Position"}
          //@ts-ignore
          onChange={onPositionChange}
        />
        {skills.length > 0 && (
          <div className={styles.skills}>
            {groupedSkills.map((item) => (
              <CustomSelect
                key={item.name}
                //@ts-ignore
                options={item.options}
                name={item.name}
                label={item.label}
                mode="multiple"
              />
            ))}
          </div>
        )}
        <div className={styles.space}></div>
        <CustomButton
          disabled={!submittable}
          text="Next"
          onClick={handleFormSubmit}
        />
      </Form>
    </div>
  );
}

export default RegisterSkills;
