import { useLocation, useNavigate } from "react-router-dom";
import styles from "./workOrder.module.css";
import { CustomButton } from "../../ui-kit";
import TitleContainer from "../../components/titleContainer/titleContainer";
import { ACTIONLIST } from "../../utils/constants";
import duck from "../../images/duck.png";
import { getWorkOrderById } from "../../utils/api";
import { useEffect, useState } from "react";

import { WorkOrderShortDto } from "../../utils/api";

function WorkOrder(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [workOrder, setWorkOrder] = useState<WorkOrderShortDto | undefined>();

  const handleOnClick = () => {
    navigate(-1);
  };

  const handleOnActionsListClick = () => {
    navigate(`${location.pathname}${ACTIONLIST}`);
  };

  const segments = location.pathname.split("/");
  const lastSegment = segments[segments.length - 1];

  useEffect(() => {
    getWorkOrderById(lastSegment).then((res) => {
      //@ts-ignore
      setWorkOrder(res?.workOrderShortDto);
    });
  }, []);

  return (
    <div className={`${styles.main} container`}>
      <TitleContainer title={"Work Order"} isLogo={true} isFilter={true} />

      <div className={styles.data_container}>
        <h3 className={styles.text_right}>{`#${workOrder?.id}`}</h3>
        <p className={`p2 ${styles.text_left}`}>
          {workOrder?.taskShortDto?.name}
        </p>
        <h3 className={styles.text_right}>Goal:</h3>
        <p className={`p2 ${styles.text_left}`}>
          {workOrder?.taskShortDto?.goal}
        </p>
        <h3 className={styles.text_right}>Due Date:</h3>
        <p className={`p2 ${styles.text_left}`}>
          {`    ${new Date(
            //@ts-ignore
            workOrder?.dueDate[0],
            //@ts-ignore
            workOrder?.dueDate[1] - 1,
            workOrder?.dueDate[2]
          )}`}
        </p>
        <h3 className={styles.text_right}>Position:</h3>
        <p className={`p2 ${styles.text_left}`}>
          {`founderPositionId: ${workOrder?.taskShortDto?.founderPositionId}`}
        </p>
        <h3 className={styles.text_right}>Grade:</h3>
        <p className={`p2 ${styles.text_left}`}>
          {workOrder?.taskShortDto?.grade}
        </p>
      </div>

      {/* <CustomButton
        text="Review Feedback"
        onClick={handleOnClick}
        color={"var(--light-yellow)"}
        textColor={"dark"}
      /> */}
      <CustomButton text="Actions List" onClick={handleOnActionsListClick} />
      <div className={styles.logo_texts_container}>
        <div className={styles.logo}>
          <img src={duck} alt="duck-icon" />
        </div>
        <div className={styles.texts_container}>
          <div className={styles.text_container}>
            <h2>Bid:</h2>
            <p className="p1">{workOrder?.bid}</p>
          </div>
          <div className={styles.text_container}>
            <h2>Bonus:</h2>
            <p className="p1">{workOrder?.bonus}</p>
          </div>
        </div>
      </div>
      <div className="narrow_buttons_container">
        <CustomButton
          text="Done"
          onClick={handleOnClick}
          color={"var(--yellow)"}
          textColor={"dark"}
          size="narrow"
        />
        <CustomButton
          text="Drop"
          onClick={handleOnClick}
          color={"var(--coral)"}
          textColor={"dark"}
          size="narrow"
        />
      </div>
      <CustomButton
        text="Back"
        onClick={handleOnClick}
        color={"var(--violet-7)"}
        textColor={"dark"}
      />
    </div>
  );
}

export default WorkOrder;
