import {
  BASE_URL,
  COBUILDERS,
  LISTS,
  POSITIONS,
  PRIMARY_LANGUAGES,
  SKILLS,
  TELEGRAM,
  USERS,
  WORKORDERS,
} from "./constants";

type ApiResponse<T> = {
  data: T;
  message: string;
};

export type Position = {
  id: number;
  name: string;
};

export type Language = {
  id: number;
  name: string;
};

type UserData = {
  email: string;
  name: string;
  last_name: string;
  avatar_url: string;
  linkedIn: string;
};

// type TelegramData = {
//   id: string;
//   username: string;
// };

type User = {
  id?: 5;
  telegramId: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
};

type RegisterRequest = {
  newUserRequest: User;
  primaryLanguageIdList: number[];
  coBuilderPositionId: number;
  skillIdList: number[];
  linkedInProfile: string;
};

const getResponse = <T>(res: Response): Promise<ApiResponse<T>> => {
  return res.ok ? res.json() : res.json().then((err) => Promise.reject(err));
};

export const getPositions = (): Promise<ApiResponse<Position[]>> => {
  return fetch(`${BASE_URL}${COBUILDERS}/${POSITIONS}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => getResponse<Position[]>(res));
};

export const getLanguages = (): Promise<ApiResponse<Language[]>> => {
  return fetch(`${BASE_URL}${PRIMARY_LANGUAGES}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => getResponse<Language[]>(res));
};
//@ts-ignore
export const getSkills = (id) => {
  return fetch(`${BASE_URL}${SKILLS}/${COBUILDERS}/${POSITIONS}/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => getResponse(res));
};
//@ts-ignore
export const cobuilderRegister = (payload): Promise<ApiResponse<any>> => {
  const userData: UserData = JSON.parse(
    localStorage.getItem("userData") ?? "{}"
  );

  return fetch(`${BASE_URL}${COBUILDERS}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${localStorage.getItem("telegramId")}`,
    },
    body: JSON.stringify({
      newUserRequest: {
        //@ts-ignore
        telegramId: JSON.parse(localStorage.getItem("telegramId")),
        //@ts-ignore
        username: JSON.parse(localStorage.getItem("telegramUserName")),
        email: userData?.email,
        firstName: userData?.name,
        lastName: userData?.last_name,
        avatarUrl: userData?.avatar_url,
      },
      primaryLanguageIdList: payload.languageIds,
      coBuilderPositionId: payload.positionId,
      skillIdList: payload.skillIds,
      linkedInProfile: userData?.linkedIn,
    } as RegisterRequest),
  }).then(getResponse);
};

export const getUser = (telegramId: number) => {
  return fetch(`${BASE_URL}${USERS}/${TELEGRAM}/${telegramId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const getCoBuilder = (coBuilderId: string) => {
  return fetch(
    `${BASE_URL}${WORKORDERS}/${LISTS}/${COBUILDERS}/${coBuilderId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((res) => getResponse(res));
};

// 13я .../api/workorders/lists?coBuilderId={coBuilderId}
export const getNewCoBuilderTasks = (coBuilderId: number) => {
  return fetch(`${BASE_URL}${WORKORDERS}/${COBUILDERS}/${coBuilderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => getResponse(res));
};

export type TaskShortDto = {
  id: number;
  name: string;
  goal: string;
  founderPositionId: number;
  grade: string;
  output: string;
  taskCategoryId: number;
  defaultBid: number;
  maxBid: number;
  taskActionList: string[];
  taskSuccessKPIList: string[];
  skillIdList: number[];
};

export type WorkOrderShortDto = {
  id: number;
  startupId: number;
  startDate: number[]; // [year, month, day]
  dueDate: number[]; // [year, month, day]
  reference: string;
  instruction: string;
  bid: number;
  bonus: number;
  taskShortDto: TaskShortDto;
  status: string;
  deliverableList: string[];
};

export type WorkOrderType = {
  workOrderShortDto: WorkOrderShortDto;
  appointmentShortDto: null | any; // Указать точный тип, если будет известен
};

// Ручка 9. Гет Когда кобилдер выбирает себе воркордер после ручки 3.
// .../api/workorders?workOrderId={workOrderId}
export const getWorkOrderById = (
  workOrderId: string
): Promise<ApiResponse<WorkOrderType>> => {
  return fetch(`${BASE_URL}${WORKORDERS}?workOrderId=${workOrderId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => getResponse<WorkOrderType>(res));
};

interface IndustryDto {
  id: number;
  name: string;
}

export interface Startup {
  id: number;
  name: string;
  shortDescription: string;
  industryDto: IndustryDto;
  startupLogoUrl: string;
}

//founder Startups /api/startups/founders/{founderId}
export const getStartups = async (Id: number): Promise<Startup[]> => {
  const response = await fetch(`${BASE_URL}/startups/founders/${Id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch startups");
  }

  const data: Startup[] = await response.json();
  return data;
};

interface FounderPositionShortDto {
  id: number;
  name: string;
}

export interface WorkOrder {
  workOrderId: number;
  appointmentId: number;
  taskName: string;
  founderPositionShortDto: FounderPositionShortDto;
  goal: string;
  status: string;
  startDate: number[];
}

// .../api/workorders/lists/startups/{startupId}
export const getWorkOrdersByStartupId = async (
  startupId: number
): Promise<WorkOrder[]> => {
  const response = await fetch(
    `${BASE_URL}/workorders/lists/startups/${startupId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch work orders");
  }

  const data: WorkOrder[] = await response.json();
  return data;
};

interface FounderPositionShortDto {
  id: number;
  name: string;
}

interface SprintForTrack {
  fullName: string;
  sprintStartDate: number[];
  workOrderForTrackList: WorkOrder[];
}

export interface StageForTrack {
  fullName: string;
  stageStartDate: number[];
  sprintForTrackList: SprintForTrack[];
}

export interface WorkOrderResponse {
  startupId: number;
  stageForTrackList: StageForTrack[];
}

// GET .../api/workorders/startups/{startupId}
export const getAllWorkOrdersByStartup = async (
  startupId: number
): Promise<WorkOrderResponse> => {
  const response = await fetch(`${BASE_URL}/workorders/startups/${startupId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch work orders");
  }

  const data: WorkOrderResponse = await response.json();
  return data;
};
