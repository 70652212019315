export const formatDate = (dateArray: number[]): string => {
    const [year, month, day] = dateArray;
    const date = new Date(year, month - 1, day);

    const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

    const suffix =
        day === 1 || day === 21 || day === 31
            ? "st"
            : day === 2 || day === 22
                ? "nd"
                : day === 3 || day === 23
                    ? "rd"
                    : "th";

    return `${formattedDate}${suffix}`;
};