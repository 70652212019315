import React from "react";
import { Button } from "@mui/material";

type CustomSmallButtonProps = {
  color?: string;
  textColor?: "light" | "dark";
  width?: string;
  height?: string;
  text?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  borderColor?: string; 
  borderWidth?: string;
  onClick?: () => void;
};

const CustomSmallButton: React.FC<CustomSmallButtonProps> = ({
  color = "linear-gradient(0.25turn, var(--violet-gr-1), var(--violet-gr-2), var(--violet-gr-3))",
  textColor = "light",
  width = "72px",
  height = "24px",
  text,
  icon,
  disabled = false,
  borderColor = "transparent",
  borderWidth = "0px", 
  onClick,
}) => {
  return (
    <Button
      sx={{
        width: width,
        height: height,
        background: color,
        borderRadius: "4px",
        fontFamily: "'SF Pro Display', 'Roboto', sans-serif",
        fontSize: "var(--font-size-xs)",
        fontWeight: "var(--font-weight-medium)",
        color:
          textColor === "light" ? "var(--violet-gr-2)" : "var(--text-light)",
        textTransform: "none",
        border: `${borderWidth} solid ${borderColor}`,
        "&:hover": {
          backgroundColor: "var(--color-secondary)",
        },
      }}
      disabled={disabled}
      startIcon={icon}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CustomSmallButton;
