import { useNavigate } from "react-router-dom";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import styles from "./RetroTask.module.css";
import { SquareButton } from "../../../../ui-kit";
import CustomSmallButton from "../../../../ui-kit/CustomSmallButton/CustomSmallButton";
import { IN_REVIEW_WORK_ORDER_PATH } from "../../../../utils/constants";

function capitalizeWords(input: string): string {
    return input
        .toLowerCase()
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

interface FounderPositionShortDto {
    id: number;
    name: string;
}

interface WorkOrderDto {
    workOrderId: number;
    appointmentId: number | null;
    taskName: string;
    founderPositionShortDto: FounderPositionShortDto;
    goal: string;
    status: string;
    startDate: number[];
}

function RetroTask({ workOrderDto }: { workOrderDto: WorkOrderDto }): React.JSX.Element {
    const { taskName, founderPositionShortDto, goal, status, startDate, appointmentId } = workOrderDto;

    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(IN_REVIEW_WORK_ORDER_PATH, { state: { appointmentId } });
    };

    const currentDate: Date = new Date();
    const targetDate = new Date(startDate[0], startDate[1] - 1, startDate[2]);
    const timeDifference: number = targetDate.getTime() - currentDate.getTime();
    const daysLeft: number = Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));

    let color: string = "var(--gray-sm)";
    if (daysLeft < 3) {
        color = "var(--red-sm)";
    } else if (daysLeft < 6) {
        color = "var(--orange-sm)";
    } else {
        color = "var(--green-sm)";
    }

    return (
        <div className={styles.main}>
            <div className={styles.common_container}>
                <div className={styles.container}>
                    <div className={styles.marker} style={{ backgroundColor: color }}></div>
                    <h3 className={styles.text_left}>{taskName}</h3>
                    <SquareButton
                        icon={<ChevronRightTwoToneIcon />}
                        altText="ArrowForwardIcon icon"
                        disabled={false}
                        variant="dark"
                        onClick={handleOnClick}
                    />

                </div>

                <div className={styles.container}>
                    <h3 className={styles.text_left}>{capitalizeWords(founderPositionShortDto.name)}</h3>
                    <p className="p2" style={{ color: color }}>
                        {daysLeft > 0 ? `${daysLeft} days` : "Overdue"}
                    </p>
                </div>
                <div className={styles.container}>
                    <p className={`p3 ${styles.text_left}`}>{goal}</p>
                    <CustomSmallButton
                        text={capitalizeWords(status)}
                        color="var(--yellow)"
                        height="24px"
                        width="104px"
                    />
                </div>
            </div>
        </div>
    );
}

export default RetroTask;
