import { useNavigate } from "react-router-dom";
import styles from "./ActionsList.module.css";
import { CustomButton } from "../../../ui-kit";

function ActionsList(): React.JSX.Element {

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <h1>Actions List</h1>
      <ol className={styles.text_container}>
        <li>Conduct market research on the global flower industry: key markets, competitors, and trends.</li>
        <li>Interview potential flower sellers to understand their pain points and needs for such a platform.</li>
        <li>Analyze competitors and identify gaps in the market.</li>
      </ol>
      <h2>Output</h2>
      <p className={styles.text_container}>
        Insights from research and interviews that help refine the platform’s unique value proposition (UVP).
      </p>

      <div className={styles.text_deliverables}>
        <h2 className={styles.deliverables_heading}>Deliverables:</h2>

        <ol>
          <li>Artefact URL 1</li>
          <li>Artefact URL 2</li>
          <li>Artefact URL 3</li>
        </ol>
      </div>

      <div className={styles.text_kpi}>
        <h2 className={styles.deliverables_heading}>Success KPI:</h2>

        <p>
          - 1
        </p>
        <p>- 2
        </p>
        <p>- 3
        </p>
      </div>


      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default ActionsList;
