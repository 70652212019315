import styles from "./loader.module.css";
import union from "../../images/union.svg";
import tracker from "../../images/tracker.svg";

function Loader() {
  return (
    <div className={styles.logo}>
      <img className={styles.union} src={union} alt="union" />
      <img className={styles.tracker} src={tracker} alt="tracker" />
    </div>
  );
}

export default Loader;
