import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SendTransactionRequest, TonConnectButton } from "@tonconnect/ui-react";
import { useTonConnectUI } from "@tonconnect/ui-react";
import styles from "./StartupAccount.module.css";
import { CustomButton } from "../../../ui-kit";
import FounderTitle from "../../../components/pagesComponents/founder/FounderTitle/FounderTitle";
import ton from "../../../images/ton.svg";

function StartupAccount(): React.JSX.Element {
  const navigate = useNavigate();
  const [tonCoinsNumber, setTonCoinsNumber] = useState(1);

  // const handleInputChange = (e: number) => {
  //   setTonCoinsNumber(e.target.value);
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value >= 1) {
      setTonCoinsNumber(value);
    } else {
      setTonCoinsNumber(1);
    }
  };

  const handleOnClick = () => {
    navigate(-1);
  };

  const transaction: SendTransactionRequest = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
    messages: [
      {
        address: "UQCmHU39chg1_j_r9CAjG9wg0-G3o2W9OztdVS-EiXWHFMz-", // message destination in user-friendly format
        amount: tonCoinsNumber.toString(), // Toncoin in nanotons
      },
    ],
  };

  const [tonConnectUI, setOptions] = useTonConnectUI();

  return (
    <div className={`${styles.main} container`}> 
      <FounderTitle title="Startup account" isLogo={true} isFilter={false} />
      <h3>Add Fund</h3>
      <div className={styles.button_container}>
        <TonConnectButton />
        <p className={"p2"}>Put number of TONcoin in nanotons</p>
        <div className={styles.inputContainer}>
          <img src={ton} className={styles.inputIcon} alt="Ton Icon" />
          <input
            type="number"
            className={styles.input}
            value={tonCoinsNumber}
            onChange={handleInputChange}
          />
        </div>
        <button
          className={styles.button}
          onClick={() => tonConnectUI.sendTransaction(transaction)}
        >
          Send transaction
        </button>
      </div>
      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default StartupAccount;
