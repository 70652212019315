import { ConfigProvider, Form } from "antd";
import styles from "./CustomSelect.module.css";

import { Select } from "antd";

type SelectOption = {
  id: number;
  name: string;
};

type CustomSelectProps = {
  options: SelectOption[];
  mode?: "tags" | "multiple";
  label: string;
  name: string;
  onChange?: () => void;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  mode = undefined,
  label,
  name,
  onChange,
}) => {
  return (
    <div className={styles.select_container}>
      <h4 className={`p2 ${styles.select_title}`}>{`* ${label}`}</h4>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionFontSize: 20,
              fontSize: 20,
              fontSizeLG: 20,
              fontFamily: "'SF Pro Display', 'Roboto', sans-serif",
              controlHeight: 60,
              optionHeight: 32,
            },
          },
        }}
      >
        <Form.Item
          name={name}
          rules={[
            {
              required: true,
              message: `Please select your ${label}!`,
              type: mode ? "array" : "string",
            },
          ]}
        >
          <Select
            mode={mode}
            allowClear
            placeholder="Please select"
            onChange={onChange}
            options={options?.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
            className={styles.select}
          />
        </Form.Item>
      </ConfigProvider>
    </div>
  );
};

export default CustomSelect;
