// GET .../api/workorders?workOrderId={workOrderId}
//Ручка 9. Гет
//А если клацает ту строчку, где в поле "appointmentId" стоит null или со страницы "Plan a new sprint" и "Urgent Tasks" (тут все будут с  null), то запрос с параметром workOrderId:

export const workOrderMockData = {
  "workOrderShortDto": {
    "id": 1,
    "startupId": 1,
    "startDate": [
      2024,
      11,
      24
    ],
    "dueDate": [
      2024,
      12,
      1
    ],
    "reference": "reference",
    "instruction": "instruction",
    "bid": 200,
    "bonus": 100,
    "taskShortDto": {
      "id": 1,
      "name": "Technology & Software. Task 1",
      "goal": "goal",
      "founderPositionId": 1,
      "grade": "grade",
      "output": "output",
      "taskCategoryId": 1,
      "defaultBid": 100,
      "maxBid": 500,
      "taskActionList": [
        "action 1",
        "action 2"
      ],
      "taskSuccessKPIList": [
        "kpi 1",
        "kpi 2"
      ],
      "skillIdList": [
        1,
        2,
        3
      ]
    },
    "status": "ASSIGNED",
    "deliverableList": [
      "deliverable 1",
      "deliverable 2",
      "deliverable 3"
    ]
  },
  "appointmentShortDto": {
    "id": 1,
    "coBuilderId": 1,
    "workOrderId": 1,
    "appointmentStart": [
      2024,
      11,
      20,
      2,
      5,
      16,
      12429000
    ],
    "stageStart": [
      2024,
      11,
      20,
      2,
      5,
      16,
      12429000
    ],
    "appointmentEnd": null,
    "iteration": 1,
    "status": "IN_PROGRESS",
    "appointmentCommentDtoList": []
  }
}