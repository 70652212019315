import { useNavigate } from "react-router-dom";
import styles from "./SprintRetro.module.css";
import SearchForm from "../../../ui-kit/SearchForm/SearchForm";
import CustomSmallButton from "../../../ui-kit/CustomSmallButton/CustomSmallButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
//import { workOrdersListsStartups } from "../../../utils/14_workOrdersListsStartupsMockData"; 
import { CustomButton } from "../../../ui-kit";
import RetroTask from "../../../components/pagesComponents/founder/SprintRetro/RetroTask";
import { getStartups, getWorkOrdersByStartupId, Startup, WorkOrder } from "../../../utils/api";
import { FounderShortDto } from "../../../utils/types";
import FounderTitle from "../../../components/pagesComponents/founder/FounderTitle/FounderTitle";

function SprintRetro(): React.JSX.Element {
  const [query, setQuery] = useState("");
  const [sortOrders, setSortOrders] = useState<WorkOrder[] | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    console.log("Search query submitted:", query);
  };

  const handleOnClick = () => {
    navigate(-1);
  };


  useEffect(() => {
    const storedData = localStorage.getItem("founderData");

    if (storedData) {
      const parsedData: FounderShortDto = JSON.parse(storedData);

      const fetchStartups = async () => {
        try {
          const startups = await getStartups(parsedData.id);

          if (startups) {
            const orders = await getWorkOrdersByStartupId(startups[0].id); //1

            const urgentTasks = orders.filter(
              (task) => task.status === "IN_REVIEW"
            );

            const resultTasks = urgentTasks.sort((a, b) => {
              const dateA = new Date(a.startDate[0], a.startDate[1] - 1, a.startDate[2]);
              const dateB = new Date(b.startDate[0], b.startDate[1] - 1, b.startDate[2]);
              return dateB.getTime() - dateA.getTime();
            });

            setSortOrders(resultTasks);
          }

        } catch (error) {
          console.error("Failed to fetch startups:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStartups();
    } else {
      console.log("Data not found in localStorage");
      setLoading(false);
    }
  }, [])

  // const urgentTasks = workOrdersListsStartups.filter(
  //   (task) => task.status === "In Review"
  // );

  if (loading) {
    return <p>Loading founder data...</p>;
  }

  return (
    <div className={`${styles.main} container`}>

      <div className={styles.wrapper}><FounderTitle title="Sprint Retro" isLogo={true} isFilter={true} /></div>
      <div className={styles.search_container}>
        <SearchForm
          query={query}
          onSearchChange={handleSearchChange}
          onSearchSubmit={handleSearchSubmit}
        />
        <div className={styles.buttons_container}>
          <CustomSmallButton
            text="&#10006; In Review"
            color="var(--yellow)"
            height="20px"
            width="95px"
          />
        </div>
      </div>

      <div>
        {sortOrders && sortOrders.length > 0 && (
          <div className={styles.days_container}>
            days <KeyboardArrowDownIcon fontSize="large" />
          </div>
        )}

        <div className={styles.container}>
          {sortOrders && sortOrders?.length > 0 ? (
            sortOrders.map((data, index) => (
              <RetroTask
                key={index}
                workOrderDto={data}
              />
            ))
          ) : (
            <p>No In Review orders found.</p>
          )}
        </div>
      </div>

      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default SprintRetro;
