import { useNavigate } from "react-router-dom";
import styles from "./NewSprintInfo.module.css";
import { CustomButton } from "../../../ui-kit";
import { MY_SPRINTS_PATH } from "../../../utils/constants";

function NewSprintInfo(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(MY_SPRINTS_PATH);
  };

  return (
    <div className={`${styles.main} container`}>
      <div className={styles.description_container}>
        <h1>Plan a new sprint</h1>
        <h2>Don't stay behind!</h2>
        <p className={`p2 ${styles.description}`}>
          If you let them Open without acceptance your work orders may
          be not so much attractive for Co-Builders.
        </p>

        <h2>Best practice:</h2>
        <ul className={`${styles.custom_list}`}>
          <li>Increase your Bids</li>
          <li>Offer more Bonuses for extra instructions</li>
        </ul>
      </div>

      <div>
        <CustomButton text="I got it" onClick={handleOnClick} />
      </div>
    </div>
  );
}

export default NewSprintInfo;
