import { Rule } from "antd/es/form";
import styles from "./CustomInput.module.css";
import { Form, Input } from "antd";

type CustomInputProps = {
  label: string;
  name: string;
  type?: string;
};

function CustomInput({
  label,
  name,
  type = "text",
}: CustomInputProps): React.JSX.Element {
  let rules: Rule[] = [];
  if (type === "text") {
    rules = [
      {
        required: true,
      },
    ];
  } else if (type === "email") {
    rules = [
      {
        type: "email",
      },
      {
        required: true,
      },
    ];
  } else if (type === "url") {
    rules = [
      {
        type: "url",
      },
      {
        required: false,
      },
    ];
  }

  return (
    <div className={styles.input_container}>
      <h4 className={`p2 ${styles.input_title}`}>
        {`${type === "url" ? label : `*${label}`}`}
      </h4>
      <Form.Item name={name} rules={rules}>
        <Input
          allowClear
          placeholder={`Enter your ${label.toLowerCase()}`}
          className={`p1 ${styles.input}`}
          type={type}
        />
      </Form.Item>
    </div>
  );
}

export default CustomInput;
