import React from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchFormProps {
  query: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ query, onSearchChange, onSearchSubmit }) => {
  return (
    <div style={{ width: '100%' }}>
      <TextField
        value={query}
        onChange={onSearchChange}
        label="Search"
        variant="outlined"
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'var(--violet-gr-1)',
              borderWidth: 5,
              borderRadius: '12px',
              boxShadow: '0 3px 3px rgba(0, 0, 0, 0.3)',
            },
            '&:hover fieldset': {
              borderColor: 'var(--violet-gr-1)',
              borderWidth: 5,
              borderRadius: '12px',
              boxShadow: '0 3px 3px rgba(0, 0, 0, 0.3)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'var(--violet-gr-1)',
              borderWidth: 5,
              borderRadius: '12px',
              boxShadow: '0 3px 3px rgba(0, 0, 0, 0.3)',
            },
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: '1.2rem',
            color: 'var(--violet-gr-1)',
          },
        }}
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={onSearchSubmit} sx={{ color: 'var(--violet-gr-1)' }}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchForm;
