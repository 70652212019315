import TextField from "@mui/material/TextField";

interface CustomTextFieldProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({ value, onChange }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <TextField
                variant="outlined"
                fullWidth
                value={value}
                onChange={onChange}
                multiline
                minRows={4}
                maxRows={10}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                        '& fieldset': {
                            border: '4px solid var(--violet-gr-2)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'var(--violet-gr-2)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'var(--violet-gr-2)',
                        },
                        width: "255px",
                        height: "160px",
                    },
                    '& .MuiInputLabel-root': {
                        color: 'var(--violet-gr-2)',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: 'var(--violet-gr-2)',
                    },
                }}
            />
        </div>
    );
};

export default CustomTextField;
