// GET ...api/cobuilders/positions
// ответ сервера с опциями позиций для кобилдера на странице регистрации

export const positionsMockData = [
  {
    id: 1,
    name: "Developer",
  },
  {
    id: 2,
    name: "Designer",
  },
  {
    id: 3,
    name: "Tester",
  },
  {
    id: 4,
    name: "Project manager",
  },
  {
    id: 5,
    name: "Product manager",
  },
  {
    id: 6,
    name: "Marketers",
  },
  {
    id: 7,
    name: "Sales Managers",
  },
];
