// GET .../api/workorders?appointmentId={appointmentId}
//Если пользователь на страницах "Sprint retro" клацает на записи, в которых есть цифра "appointmentId", то запрос на бек идёт с параметром appointmentId:

export const workOrderMockData = {
  "workOrderShortDto": {
    "id": 1,
    "startupId": 1,
    "startDate": [
      2024,
      11,
      24
    ],
    "dueDate": [
      2024,
      12,
      1
    ],
    "reference": "reference",
    "instruction": "instruction",
    "bid": 200,
    "bonus": 100,
    "taskShortDto": {
      "id": 1,
      "name": "Technology & Software. Task 1",
      "goal": "Validate the need for a global e-commerce platform for flower sellers",
      "founderPositionId": 1,
      "grade": "Middle",
      "output": "output",
      "taskCategoryId": 1,
      "defaultBid": 100,
      "maxBid": 500,
      "taskActionList": [
        "action 1",
        "action 2"
      ],
      "taskSuccessKPIList": [
        "kpi 1",
        "kpi 2"
      ],
      "skillIdList": [
        1,
        2,
        3
      ]
    },
    "status": "ASSIGNED",
    "deliverableList": [
      "deliverable 1",
      "deliverable 2",
      "deliverable 3"
    ]
  },
  "appointmentShortDto": {
    "id": 1,
    "coBuilderId": 1,
    "workOrderId": 1,
    "appointmentStart": [
      2024,
      11,
      20,
      2,
      5,
      16,
      12429000
    ],
    "stageStart": [
      2024,
      11,
      20,
      2,
      5,
      16,
      12429000
    ],
    "appointmentEnd": null,
    "iteration": 1,
    "status": "IN_PROGRESS",
    "appointmentCommentDtoList": []
  }
}