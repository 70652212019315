import { Link } from "react-router-dom";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import styles from "./MySprints.module.css";
import { CustomButton } from "../../../ui-kit";
import {
  // FOUNDER_MAIN_PATH,
  NEW_SPRINT_PATH,
  SPRINT_RETRO_PATH,
  STARTUP_ACCOUNT,
  STARTUP_TRACK_PATH,
  URGENT_TASKS_PATH,
} from "../../../utils/constants";
// import { mockData } from "../../../utils/01.mockData";
import FounderTitle from "../../../components/pagesComponents/founder/FounderTitle/FounderTitle";

function MySprints(): React.JSX.Element {
  // const navigate = useNavigate();

  // const handleAvatarClick = () => {
  //   navigate(FOUNDER_MAIN_PATH);
  // };

  // const initials = `${mockData?.founderMockData?.founderShortDto.userDto.firstName.charAt(
  //   0
  // )}${mockData?.founderMockData?.founderShortDto.userDto.lastName.charAt(0)}`;

  return (
    <div className={`${styles.main} container`}>
      <div className={styles.sprints__header}>
        <FounderTitle isFilter={false} isLogo={true} title={"My Sprints"} />
        {/* <div className={styles.sprints__header__logo}>
          <RoundButton
            text={initials}
            onClick={handleAvatarClick}
            disabled={false}
          />
        </div>
        <div className={styles.sprints__header__text}>
          <h1>My Sprints</h1>
        </div> */}
      </div>
      <div className={styles.sprints__buttons_container}>
        <Link to={STARTUP_TRACK_PATH}>
          <CustomButton text="Startup Track" />
        </Link>
        <Link to={SPRINT_RETRO_PATH}>
          <CustomButton text="Sprint Retro" />
        </Link>
        <Link to={NEW_SPRINT_PATH}>
          <CustomButton
            text="Plan a new Sprint"
            icon={
              <NotificationsNoneOutlinedIcon
                style={{ color: "var(--white)" }}
              />
            }
          />
        </Link>
        <Link to={URGENT_TASKS_PATH}>
          <CustomButton text="Urgent Tasks" />
        </Link>
        <Link to={STARTUP_ACCOUNT}>
          <CustomButton text="Startup Account" />
        </Link>
      </div>
    </div>
  );
}

export default MySprints;
