import { useState } from "react";
import { Button } from "@mui/material";

interface ImageUploaderProps {
    onImageUpload: (image: string) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload }) => {
    const [image, setImage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            if (!file.type.startsWith("image/")) {
                setError("Please upload the image.");
                return;
            }

            const reader = new FileReader();

            reader.onloadend = () => {
                const imageURL = reader.result as string;
                setImage(imageURL);
                onImageUpload(imageURL);
                setError(null);
            };

            reader.onerror = () => {
                setError("Error while loading file.");
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                    variant="outlined"
                    component="label"
                    sx={{
                        backgroundColor: "transparent",
                        color: "black",
                        border: "4px solid var(--violet-gr-2)",
                        borderRadius: "20px",
                        width: "255px",
                        height: "160px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "transparent",
                            borderColor: "var(--violet-gr-2)",
                        },
                    }}
                    aria-label="upload an image"
                >
                    Screenshot +
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageUpload}
                    />
                </Button>
            </div>

            {error && (
                <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                    {error}
                </div>
            )}

            {image && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <img
                        src={image}
                        alt="Uploaded"
                        style={{ width: "255px", height: "200px", objectFit: "cover" }}
                    />
                </div>
            )}
        </div>
    );
};

export default ImageUploader;