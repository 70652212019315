import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FounderMain.module.css";
import { CustomButton } from "../../../ui-kit";
import { MY_SPRINTS_PATH } from "../../../utils/constants";
import { FounderShortDto } from "../../../utils/types";
import StartupCard from "../../../components/pagesComponents/founder/Main/StartupCard";
import { getStartups, Startup } from "../../../utils/api";

function FounderMain(): React.JSX.Element {
  const navigate = useNavigate();

  const [founderData, setFounderData] = useState<FounderShortDto | undefined>();
  const [founderStartups, setFounderStartups] = useState<Startup[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleOnClick = () => {
    navigate(MY_SPRINTS_PATH);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("founderData");

    if (storedData) {
      const parsedData: FounderShortDto = JSON.parse(storedData);
      setFounderData(parsedData);

      const fetchStartups = async () => {
        try {
          const startups = await getStartups(parsedData.id);
          setFounderStartups(startups);
        } catch (error) {
          console.error("Failed to fetch startups:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStartups();
    } else {
      console.log("Data not found in localStorage");
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <p>Loading founder data...</p>;
  }

  return (
    <div className={`${styles.main} container`}>
      <h2>Hey there!</h2>
      {founderData ? (
        <>
          <h2>{`${founderData.userDto.firstName} ${founderData.userDto.lastName}`}</h2>
          <h2>@{founderData.userDto.username}</h2>
          <div className={styles.text}>
            {founderStartups && (
              <>
                It seems that you are one of the Founders of the {founderStartups[0].name}
              </>)}
          </div>

          <div>
            {founderStartups && founderStartups.map((startup) => (
              <StartupCard
                key={startup.id}
                name={startup.name}
                industry={startup.industryDto.name}
                description={startup.shortDescription}
                logo={startup.startupLogoUrl}
              />
            ))}
          </div>
        </>
      ) : (
        <p>No founder data available</p>
      )}
      <CustomButton text="Next" onClick={handleOnClick} />
    </div>
  );
}

export default FounderMain;
