import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import AddIcon from "@mui/icons-material/Add";
import styles from "./registerUser.module.css";
import { CustomButton, RoundButton } from "../../ui-kit";
import CustomInput from "../../ui-kit/CustomInput/CustomInput";
import { MAIN, REGISTER_SKILLS } from "../../utils/constants";

function RegisterUser(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  //@ts-ignore
  const platform = JSON.parse(localStorage.getItem("telegramUserPlatform"));

  const [submittable, setSubmittable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  function handleFormSubmit() {
    navigate(REGISTER_SKILLS);
    localStorage.setItem("userData", JSON.stringify(values));
  }

  // const handleOnClickToMain = () => {
  //   navigate(MAIN);
  // };

  return (
    <div
      className={`${
        platform === "ios" ? styles.main_ios : styles.main
      } container`}
    >
      {/* <div className={styles.avatar}>
          <RoundButton
            icon={<AddIcon />}
            altText="Add icon"
            disabled={false}
            onClick={handleOnClick}
          />
        </div> */}
      <Form
        form={form}
        name="register"
        layout="vertical"
        autoComplete="off"
        className={styles.form}
      >
        <CustomInput name={"name"} label={"First Name"} />
        <CustomInput name={"last_name"} label={"Last Name"} />
        <CustomInput name={"email"} label={"Email"} type={"email"} />
        <CustomInput
          name={"linkedIn"}
          label={"LinkedIn Profile"}
          type={"url"}
        />
        <CustomInput name={"avatar_url"} label={"Avatar Link"} type={"url"} />
        <div className={styles.space}></div>
        <CustomButton
          disabled={!submittable}
          text="Next"
          onClick={handleFormSubmit}
        />

        {/* <CustomButton text="To MAIN Page" onClick={handleOnClickToMain} /> */}
      </Form>
    </div>
  );
}

export default RegisterUser;
