import { useNavigate } from "react-router-dom";
import { SquareButton } from "../../../../ui-kit";
import CustomSmallButton from "../../../../ui-kit/CustomSmallButton/CustomSmallButton";
import styles from "./NewTask.module.css";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import { OPEN_WORK_ORDER_PATH } from "../../../../utils/constants";

function capitalizeWords(input: string): string {
  return input
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

interface FounderPositionShortDto {
  id: number;
  name: string;
}

interface WorkOrderDto {
  workOrderId: number;
  taskName: string;
  founderPositionShortDto: FounderPositionShortDto;
  goal: string;
  status: string;
  startDate: number[];
}

function NewTask({ workOrderDto }: { workOrderDto: WorkOrderDto }): React.JSX.Element {
  const { taskName, founderPositionShortDto, goal, status, workOrderId  } = workOrderDto;

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(OPEN_WORK_ORDER_PATH, { state: { workOrderId } });
};
  

  return (
    <div className={styles.main}>
      <div className={styles.common_container}>
        <div className={styles.container}>
          <p className={styles.text_left}>{taskName}</p>
          <SquareButton
            icon={<ChevronRightTwoToneIcon />}
            altText="ArrowForwardIcon icon"
            disabled={false}
            variant="dark"
            onClick={handleOnClick}
          />
        </div>

        <div className={styles.container}>
          <h3 className={styles.text_left}>{capitalizeWords(founderPositionShortDto.name)}</h3>
        </div>
        <div className={styles.container}>
          <p className={`p3 ${styles.text_left}`}>{goal}</p>
          <CustomSmallButton
            text={
              status.toLowerCase()[0].toUpperCase() +
              status.slice(1).toLowerCase()
            }
            color="var(--violet-7)"
            height="24px"
          />
        </div>
      </div>
    </div>


  );
}

export default NewTask;
