import { useNavigate } from "react-router-dom";
import styles from "./RevisionRequest.module.css";
import { CustomButton, CustomTextField, RoundButton } from "../../../ui-kit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import ImageUploader from "../../../ui-kit/ImageUploader/ImageUploader";

function RevisionRequest(): React.JSX.Element {
    const navigate = useNavigate();
    
    const handleOnClick = () => {
        navigate(-1);
    };

    const handleClick = () => {
        console.log("Button clicked!");
    };

    const [inputReferencesValue, setInputReferencesValue] = useState('');

    const handleInputReferencesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputReferencesValue(value);
    };

    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const handleImageUpload = (image: string) => {
        setImageUrl(image);
    };

    return (
        <div className={`${styles.main} container`}>
            <h1>Revision Request</h1>

            {imageUrl ? (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <img
                        src={imageUrl}
                        alt="Uploaded"
                        style={{ width: "255px", height: "200px", objectFit: "cover" }}
                    />
                </div>
            ) : (
                <ImageUploader onImageUpload={handleImageUpload} />
            )}

            <h2>Instructions:</h2>

            <CustomTextField value={inputReferencesValue} onChange={handleInputReferencesChange} />

            <div className={`${styles.bonus_container}`}>
                <RoundButton
                    icon={<RemoveIcon />}
                    altText="Remove icon"
                    disabled={false}
                    onClick={handleClick}
                />
                <h2>Bonus: 100</h2>
                <RoundButton
                    icon={<AddIcon />}
                    altText="Add icon"
                    disabled={false}
                    onClick={handleClick}
                />
            </div>

            <div className="narrow_buttons_container">
                <CustomButton
                    text="Back"
                    onClick={handleOnClick}
                    color={"var(--violet-7)"}
                    textColor={"dark"}
                    size="narrow"
                />
                <CustomButton
                    text="Revision"
                    onClick={handleOnClick}
                    color={"var(--yellow)"}
                    textColor={"dark"}
                    size="narrow"
                />
            </div>
        </div>
    );
}

export default RevisionRequest;