import { useNavigate } from "react-router-dom";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import { RoundButton } from "../../ui-kit";
import { FILTERS } from "../../utils/constants";
import { TypeCoBuilderData } from "../../utils/types";

type TitleContainerProps = {
  title: string;
  isLogo: boolean;
  isFilter: boolean;
};

function TitleContainer({
  title,
  isLogo,
  isFilter,
}: TitleContainerProps): React.JSX.Element {
  const navigate = useNavigate();

  const coBuilderData: TypeCoBuilderData | null = JSON.parse(
    localStorage.getItem("cobuilderData") || "null"
  );

  const coBuilderInitials = coBuilderData
    ? `${coBuilderData.userDto.firstName.charAt(
        0
      )}${coBuilderData.userDto.lastName.charAt(0)}`
    : "";

  const avatar = (() => {
    const item = localStorage.getItem("telegramUserAvatar");
    return item && item !== "undefined"
      ? JSON.parse(item)
      : "https://avatars.mds.yandex.net/i?id=c9dcd0096abdbae134ba6719808d38df7315b1b3-10114686-images-thumbs&n=13";
  })();

  const handleFilterClick = (): void => {
    navigate(FILTERS);
  };

  // const handleAvatarClick = (): void => {
  //   navigate(MYASSIGNMENTS);
  // };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "40px",
          width: "40px",
        }}
      >
        {isLogo && !avatar ? (
          <RoundButton
            disabled={false}
            // onClick={handleAvatarClick}
            text={coBuilderInitials}
          />
        ) : (
          <RoundButton
            disabled={false}
            // onClick={handleAvatarClick}
            img={avatar}
          />
        )}
      </div>
      <h1>{title}</h1>
      <div
        style={{
          height: "40px",
          width: "40px",
        }}
      >
        {isFilter && (
          <RoundButton
            icon={<TuneTwoToneIcon />}
            altText="TuneTwoToneIcon icon"
            disabled={false}
            variant="dark"
            onClick={handleFilterClick}
          />
        )}
      </div>
    </div>
  );
}

export default TitleContainer;
